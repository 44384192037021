<template>
  <div class="create-product-step">
    <div class="form-content content" v-if="!isSelectTableVisible && !isTagsTableVisible">
      <!--  Header    -->
      <CRow class="p-0 m-0" v-if="isHeader">
        <CCol col="12" class="d-flex p-0">
          <h3 class="mb-3 mr-1 bread-content">{{ stepNumber ? stepNumber + '. Products' : 'Products' }}</h3>
          <IconWithTooltip class="zq--header-tooltip" :text="descriptions.products"/>
        </CCol>
      </CRow>
      <!--  All products included     -->
      <SwitchRow
        v-if="!isProductsTagsOnly"
        :label="texts.product.allLabel"
        :valueProp="isAllProducts"
        @swipeHandler="swipeIsAllProductsHandler"
        @updateHandler="isAllProductsUpdate"
        :disabled="isFieldDisabled"
        :toolTipText="descriptions.allProductsIncluded"
      />
      <!--  Products Result Table  -->
      <DepResultTable
        v-if="!isAllProducts"
        :isProductsTagsOnly="isProductsTagsOnly"
        @toSelectTable="toSelectTable"
        @toSelectTags="toSelectTags"
        @updateCurrentTypes="updateCurrentTypes"
        @updateData="handleUpdateProductsData"
        :propData="productsData"
        :isValid="isValid"
        :currentTypesProps="currentTypes"
        :label="texts.product.resultRestrictLabel"
        storeType="products"
        :buttonLabel="texts.product.resultBtnLabel"
        :tagsButtonLabel="texts.product.resultGroupBtnLabel"
        :errorMessage="texts.product.resultError"
        :disabled="isFieldDisabled"
        :toolTipText="descriptions.minimumShouldMatch"
        :isSelectedTable="isSelectedTableVisible"
        :isSelectTagsAvailable="true"
        :isTagsTableVisible="isTagsTableVisible"
      />
    </div>
    <SelectedProductsTable
      v-if="isSelectedTableVisible && selectedData.length"
      :productsData="selectedData"
      :disabled="isFieldDisabled"
      :pageNumber="stepNumber"
      :chosenRewards="selectedData"
      @updateProducts="updateProducts"
    />
    <DepSelectTagsTable
      v-if="isTagsTableVisible"
      @toResultTable="toResultTable"
      @updateSelectData="productsTagsSelectData"
      :fields="fields"
      storeType="tags"
      entityType="Product"
      :title="stepNumber ? stepNumber + '. Products / Product Tags' : 'Products / Product Tags'"
      :selectedData="selectedData"
      :formData="formData"
      :isHeader="isHeader"
    />
    <DepSelectTable
      v-if="isSelectTableVisible"
      @toResultTable="toResultTable"
      @toSelectedTable="toSelectedTable"
      @updateSelectData="productsSelectData"
      :formData="formData"
      storeType="products"
      :title="stepNumber ? stepNumber + '. Products / Individual Products' : 'Products / Individual Products'"
      :fields="fields"
      :selectedData="selectedData"
      :isSelectedTable="true"
      :isHeader="isHeader"
    />
  </div>
</template>

<script>
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import SwitchRow from '@/shared/components/SwitchRow';
import DepSelectTable from '@/shared/components/DepTables/DepSelectTable';
import DepResultTable from '@/shared/components/DepTables/DepResultTable';
import SelectedProductsTable from '@/shared/components/supportModels/products/SelectedProductsTable';
import DepSelectTagsTable from '@/shared/components/DepTables/DepSelectTagsTable';
import {isCompFieldDisabled} from "@/utils/validationUtils";
import {competitionsTexts} from "@/config/pageTexts/competitions.json";

export default {
  name: "AddProducts",
  components: {
    IconWithTooltip,
    SwitchRow,
    DepSelectTable,
    DepResultTable,
    DepSelectTagsTable,
    SelectedProductsTable,
  },
  props: {
    productsData: Object,
    descriptions: Object,
    isValid: Boolean,
    isAdvanced: {
      type: Boolean,
      default: false
    },
    stepNumber: {
      type: [String, Number],
      default: ''
    },
    status: {
      type: String,
      default: 'Ready'
    },
    isProductsTagsOnly: {
      type: Boolean,
      default: false
    },
    isHeader: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      texts: {
        ...competitionsTexts
      },
      fields: [
        "select",
        "id",
        "created",
        "name",
        "key",
        "description",
      ],
      isSelectTableVisible: false,
      isSelectedTableVisible: false,
      isTagsTableVisible: false,
      isAllProducts: true,
      selectedData: [],
      currentTypes: {},
      formData: {
        shouldMatchAtLeast: 1,
        dependantOn: {
          must: [],
          mustNot: [],
          should: []
        }
      }
    }
  },
  computed: {
    isFieldDisabled() {
      return isCompFieldDisabled(this.status);
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.currentTypes = this.productsData.currentTypes ?? {};
      this.isAllProducts = this.productsData.isAllProductsInclude;
      this.selectedData = this.productsData.selectedData;

      if (
        this.selectedData.length
        && typeof this.selectedData[0] === 'object'
        && Object.hasOwn(this.selectedData[0], 'productRefId')
      ) {
        this.isSelectedTableVisible = true;
      }
      this.formData = this.productsData.formData;
    },
    swipeIsAllProductsHandler(direction) {
      this.isAllProducts = direction === 'right';
    },
    isAllProductsUpdate(val) {
      this.isAllProducts = val;
    },
    toSelectTable() {
      this.isSelectTableVisible = true;
      this.isSelectedTableVisible = false;
      this.isTagsTableVisible = false;
    },
    toSelectTags() {
      this.isTagsTableVisible = true;
      this.isSelectTableVisible = false;
      this.isSelectedTableVisible = false;
    },
    toSelectedTable() {
      this.isSelectedTableVisible = true;
      this.isSelectTableVisible = false;
      this.isTagsTableVisible = false;
    },
    toResultTable() {
      this.isSelectTableVisible = false;
      this.isTagsTableVisible = false;
      if (this.selectedData.length && Object.hasOwn(this.selectedData[0], 'productRefId')) {
        this.isSelectedTableVisible = true;
      }
    },
    updateProducts(products) {
      this.selectedData = products;
      this.$emit(
        'updateProductsData',
        {...this.productsData, formData: this.formData, selectedData: this.selectedData}
      );
    },
    updateCurrentTypes(val) {
      this.currentTypes = val;
      this.$emit('updateCurrentTypes', val);
    },
    resetAllProducts(val) {
      if (val) {
        const dependantFormData = {
          shouldMatchAtLeast: 1,
          dependantOn: {
            must: [],
            mustNot: [],
            should: []
          }
        }
        this.formData = {...this.formData, ...dependantFormData};

        this.$emit('updateProductsData', {
          ...this.productsData,
          formData: this.formData,
          selectedData: []
        })
      }
    },
    productsSelectData({selectedData, formData}) {
      this.selectedData = this.selectedData.filter(function(el) { return el.hasOwnProperty('productRefId'); });

      selectedData.map((item) => {
        item.valid = formData.dependantOn.mustNot.includes(item.id) || formData.dependantOn.must.includes(item.id) || formData.dependantOn.should.includes(item.id);
        return item;
      })
      this.selectedData = [...new Set([...selectedData, ...this.selectedData])];

      this.selectedData = this.selectedData.filter(
        (obj, index) =>
          this.selectedData.findIndex((item) => item.id === obj.id) === index
      );

      this.formData = formData;
      this.$emit('updateProductsData', {...this.productsData, formData: this.formData, selectedData: this.selectedData});
    },
    productsTagsSelectData({selectedData, formData}) {
      this.selectedData = this.selectedData.filter(function(el) { return !el.hasOwnProperty('productRefId'); });

      selectedData.map((item) => {
        item.valid = formData.dependantOn.mustNot.includes(item.id) || formData.dependantOn.must.includes(item.id) || formData.dependantOn.should.includes(item.id);
        return item;
      })
      this.selectedData = [...new Set([...selectedData, ...this.selectedData])];

      this.selectedData = this.selectedData.filter(
        (obj, index) =>
          this.selectedData.findIndex((item) => item.id === obj.id) === index
      );

      this.formData = formData;
      this.$emit('updateProductsData', {...this.productsData, formData: this.formData, selectedData: this.selectedData});
    },
    handleUpdateProductsData(val) {
      this.selectedData = val.selectedData;
      this.$emit('updateProductsData', {...this.productsData, formData: val.formData, selectedData: val.selectedData});
    }
  },
  destroyed() {
    this.$emit('resetValidation');
  },
  watch: {
    isAllProducts(val) {
      this.resetAllProducts(val);
      this.$emit('updateIsAllProductsInclude', val)
    }
  }
}
</script>

<style lang="scss">
.create-product-step {
  .product-numberOfRounds {
    .fa-question-circle-o {
      position: absolute;
      top: 0;
      right: -20px;
    }
  }
}
</style>
