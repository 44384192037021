<template>
  <CRow class="points-strategy-detail">
    <CCol col="12">
      <div class="points-strategy">
        <div class="row zq--form-row">
          <span class="zq--form-row--label col-12 col-sm-3 col-md-3 col-lg-3 col-xl-2">
            {{ texts.scorePoints.operator }}
          </span>
          <multiselect
            v-model="operator"
            :options="operatorOptions"
            :hide-selected="true"
            :selectLabel="texts.scorePoints.operator"
            :placeholder="texts.scorePoints.operator"
            class="zq--multiselect operator-select"
            track-by="key"
            label="name"
          />
        </div>
        <div class="row zq--form-row">
          <span class="zq--form-row--label col-12 col-sm-3 col-md-3 col-lg-3 col-xl-2">
            {{ texts.scorePoints.pointsValue }}
          </span>
          <input
            class="points-input"
            v-model="pointsValue"
            :placeholder="texts.scorePoints.pointsValue"
            :name="'pointsValue'"
            type="number"
            min="0"
          />
        </div>
        <div
          class="row zq--form-row"
          v-if="isPointsValueUpper"
        >
          <div
            class="connective"
            v-if="operator.key === 'Between'"
          >
            {{ texts.scorePoints.and }}
          </div>
        </div>

        <div
          class="row zq--form-row"
          v-if="isPointsValueUpper"
        >
          <span class="zq--form-row--label col-12 col-sm-3 col-md-3 col-lg-3 col-xl-2">
            {{ texts.scorePoints.pointsValueUpper }}
          </span>
          <input
            class="points-input"
            v-model="pointsValueUpper"
            :placeholder="texts.scorePoints.pointsValueUpper"
            :name="'pointsValueUpper'"
            type="number"
            min="0"
          />
        </div>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import { achievementsTexts } from '@/config/pageTexts/achievements.json';
import conditionalOperator from '@/generated/ziqni/fields/ConditionalOperatorZq.js'

export default {
  name: 'EditPointsStrategy',
  components: {  },
  props: {
    strategies: Object
  },
  data() {
    return {
      texts: {
        ...achievementsTexts
      },
      basicIsCollapsed: false,
      data: {},
      operatorOptions: [],
      operator: [],
      pointsValueUpper: null,
      pointsValue: 0,
    }
  },
  created() {
    this.operatorOptions = this.transformFromCamelCaseArray(conditionalOperator.allowableValuesKeys)

    this.operator = this.operatorOptions.find(obj => obj['key'] === this.strategies.pointsStrategy.operator);
    this.pointsValue = this.strategies.pointsStrategy.pointsValue;
    this.pointsValueUpper = this.strategies.pointsStrategy.pointsValueUpper;
  },
  computed: {
    isPointsValueUpper() {
      return !!(this.strategies.pointsStrategy.operator === 'Between' || this.strategies.pointsStrategy.operator === 'AverageBetween');
    }
  },
  methods: {
    transformFromCamelCaseArray(arr) {
      return arr.map(word => {
        const name = word.replace(/([A-Z])/g, ' $1').trim()
        return {key: word, name}
      })
    }
  },
  watch: {
    operator(value) {
      if (value) {
        this.operator = value;
        this.$emit('updateStrategiesOperatorData', value);

        if ((value.key === 'Between' || value.key === 'AverageBetween') && !this.pointsValueUpper) {
          this.pointsValueUpper = +this.pointsValue + 10;
        }

        if ((value.key !== 'Between' || value.key !== 'AverageBetween') && this.pointsValueUpper) {
          this.$emit('updateStrategiesPointsValueUpperData', null);
        }
      }
    },
    pointsValue(value) {
      if (value) {
        this.pointsValue = value;
        this.$emit('updateStrategiesPointsValueData', value);
      }
    },
    pointsValueUpper(value) {
      if (value) {
        this.pointsValueUpper = value;
        this.$emit('updateStrategiesPointsValueUpperData', value);
      }
    }
  },
}
</script>

<style scoped lang="scss">

.points-strategy {
  display: flex;
  flex-direction: column;

  .connective {
    text-transform: uppercase;
  }

  .input-wrapper {
    display: flex;

    .input-title {
      padding-left: 7px;
      padding-bottom: 5px;
    }
  }
  .operator-select {
    width: 200px;
  }
  .points-input {
    width: 150px;
    border: none;
    margin-left: 5px;

    &:focus {
      outline: none;
    }
  }
}
</style>